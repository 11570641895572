<template>
  <div class="login-container">
    <h2>Log in to {{ subdomain }}</h2>
    <form @submit.prevent="login" class="login-form">
      <input v-model="email" type="email" placeholder="Email address" required class="input-field">
      <input v-model="password" type="password" placeholder="Password" required class="input-field">
      <button type="submit" class="submit-button">Login</button>
    </form>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword, getIdToken } from "firebase/auth";
import { initializeApp } from "firebase/app";
import axios from '@/axios-config';

const firebaseConfig = {
    apiKey: "AIzaSyCvWcRU21tt5D9z0i4KoEQ3maEUVsLi9lE",
    authDomain: "replifactory-login.firebaseapp.com",
    projectId: "replifactory-login",
    storageBucket: "replifactory-login.appspot.com",
    messagingSenderId: "381653735337",
    appId: "1:381653735337:web:ae7b1c320d7c64881c56b8",
    measurementId: "G-5CW4Y98XHN"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default {
  name: 'LoginComponent',
  data() {
    return {
      email: '',
      password: '',
      subdomain: this.getSubdomain()
    };
  },
  methods: {
    getSubdomain() {
      // Extract the subdomain from window.location
      const hostParts = window.location.hostname.split('.');
      if (hostParts.length >= 3) {
        return hostParts[0]; // Assuming the subdomain is the first segment
      }
      return 'your site'; // Default if no subdomain is found
    },
    async login() {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        const idToken = await getIdToken(userCredential.user);


        // Send the ID token to your server
        const response = await axios.post(`${window.location.origin}/auth/verifyIdToken`, {}, {
          headers: {
            'Authorization': `Bearer ${idToken}`
          }
        });

        console.log('Server response:', response.data);
        this.$emit('login', { idToken, user: userCredential.user });
      } catch (error) {
        console.error('Login Error:', error);
        alert('Login failed. Please check your email and password.');
      }
    }
  }
};
</script>

<style scoped>
h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  font-family: 'Lato', sans-serif;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  background-color: white;
}

.input-field {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.submit-button {
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: #007BFF;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #0056b3;
}
</style>
