<template>
  <div class="logout-container">
    <h2>Are you sure you want to log out?</h2>
    <button @click="confirmLogout">Log Out</button>
  </div>
</template>

<script>
export default {
  name: 'LogoutComponent',
  methods: {
    confirmLogout() {
      console.log('Logging out...');
      this.$axios.post('/post-logout')
        .then(() => {
          this.$router.push('/login');
        })
        .catch((error) => {
          console.error('Logout Error:', error);
        });
    }
  }
};
</script>
