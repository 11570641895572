import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import LoginComponent from './components/LoginComponent.vue'
import LogoutComponent from './components/LogoutComponent.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/login', component: LoginComponent },
    { path: '/', component: { template: '<div>Redirecting...</div>' } },
    { path: '/logout', component: LogoutComponent }

  ],
})

createApp(App).use(router).mount('#app')



