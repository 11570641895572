<template>
  <div id="app">
    <login v-if="!isLoggedIn" @login="handleLogin"></login>
    <!-- Assuming there's a conditional part here for logged-in users -->
    <div v-if="errorMessage" class="alert">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import LoginComponent from './components/LoginComponent.vue';

export default {
  name: 'App',
  components: {
    Login: LoginComponent
  },
  data() {
    return {
      isLoggedIn: false,// Default to not logged in
      errorMessage: '' // Store error messages
    };
  },
  methods: {
    handleLogin() {
      console.log('User logged in');
      this.isLoggedIn = true;
      this.errorMessage = ''; // Clear any error messages on successful login
      this.$router.push('/');
      window.location.reload();
    },

    checkSession() {
      fetch('/auth/verify-session', {
        credentials: 'include' // Necessary to include cookies in the request
      })
      .then(response => {
        if (response.ok) {
          this.isLoggedIn = true;
          console.log('Session is active');
        } else if (response.status === 403) {
          window.alert('User not authorized for this device');
          console.error('Unauthorized access');
        } else {
          this.isLoggedIn = false;
          console.log('Session is not active');
        }
      })
      .catch(error => {
        this.errorMessage = 'Error verifying session';
        console.error('Error verifying session:', error);
      });
    }

  },
  created() {
    // Automatically check for session on app creation
    this.checkSession();
  }
}
</script>
<style>
.alert {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
  margin-bottom: 15px;
}
</style>